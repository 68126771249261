export default [
  // {
  //   header: 'Dashboard',
  // },
  {
    title: 'Dashboard',
    // icon: 'HomeIcon',
    route: { name: 'dashboard-homepage-v1' },
  },
  {
    title: 'Revenue',
    // icon: 'HomeIcon',
    route: { name: 'dashboard-v2' },
  },
  // {
  //   title: 'Dashboard V1',
  //   icon: 'HomeIcon',
  //   route: { name: 'dashboard-homepage-v1' },
  // },
  {
    header: 'Product Management',
    icon: 'ShoppingCartIcon',
  },
  {
    title: 'Product',
    // icon: 'ShoppingCartIcon',
    children: [
      {
        title: 'Product Categories',
        route: { name: 'product-category-add' },
      },
      // {
      //   title: 'Product Categories',
      //   route: { name: 'product-category-list' },
      // },
      // {
      //   title: 'Add new category',
      //   route: { name: 'product-category-add' },
      // },
      {
        title: 'Product',
        route: { name: 'product-list' },
      },
      // {
      //   title: 'Add new product',
      //   route: { name: 'product-add' },
      // },
      {
        title: 'Tìm kiếm thuộc tính',
        route: { name: 'attribute-edit' },
      },
    ],
  },
  {
    title: 'Trademark',
    // icon: 'ShoppingBagIcon',
    route: { name: 'trademark-list' },
    // children: [
    //   {
    //     title: 'Trademark',
    //     route: { name: 'trademark-list' },
    //   },
    //   {
    //     title: 'Add new trademark',
    //     route: { name: 'trademark-add' },
    //   },
    // ],
  },
  {
    title: 'Origin',
    // icon: 'NavigationIcon',
    route: { name: 'origin-list' },
    // children: [
    //   {
    //     title: 'Origin',
    //     route: { name: 'origin-list' },
    //   },
    //   {
    //     title: 'Add new origin',
    //     route: { name: 'origin-add' },
    //   },
    // ],
  },
  {
    title: 'Supplier',
    // icon: 'TruckIcon',
    route: { name: 'supplier-list' },
    // children: [
    //   {
    //     title: 'Supplier',
    //     route: { name: 'supplier-list' },
    //   },
    //   {
    //     title: 'Add new supplier',
    //     route: { name: 'supplier-add' },
    //   },
    // ],
  },
  {
    title: 'Transporters',
    // icon: 'ApertureIcon',
    route: { name: 'shipping' },
  },
  {
    header: 'Marketing Channel',
    icon: 'GiftIcon',
  },
  {
    title: 'Coupon',
    // icon: 'ApertureIcon',
    route: { name: 'coupon-list' },
  },
  {
    title: 'Promotion',
    // icon: 'ApertureIcon',
    route: { name: 'promotion-list' },
  },
  {
    title: 'Flash sale list',
    // icon: 'ApertureIcon',
    route: { name: 'flash-sale-list' },
  },
  // {
  //   title: 'Flash sale',
  //   // icon: 'ApertureIcon',
  //   route: { name: 'flashsale' },
  // },
  {
    title: 'Combo List',
    // icon: 'ApertureIcon',
    route: { name: 'combo' },
  },
  {
    title: 'Product Bundle',
    // icon: 'ApertureIcon',
    route: { name: 'bundle' },
  },
  {
    header: 'Order Management',
    icon: 'ShoppingBagIcon',
  },
  {
    title: 'Order',
    // icon: 'ArchiveIcon',
    children: [
      {
        title: 'Order',
        route: { name: 'order-list' },
      },
      {
        title: 'Order Add',
        route: { name: 'order-add' },
      },
      // {
      //   title: 'Order Cancel',
      //   // icon: 'ArchiveIcon',
      //   route: { name: 'order-cancel' },
      // },
      {
        title: 'Order Return/Refund',
        // icon: 'ArchiveIcon',
        route: { name: 'order-return' },
      },
      {
        // title: 'Order Shopee',
        // route: { name: 'order-list-shopee' },
      },
    ],
  },
  {
    header: 'Warehouse Management',
    icon: 'PackageIcon',
  },
  // {
  //   title: 'Warehouse V1',
  //   // icon: 'BoxIcon',
  //   route: { name: 'warehouse-list' },
  // },
  {
    title: 'Warehouse',
    // icon: 'BoxIcon',
    children: [
      // {
      //   title: 'Danh sách nhà kho',
      //   route: { name: 'warehouselist-list' },
      // },
      {
        title: 'Danh sách kệ hàng',
        // icon: 'ArchiveIcon',
        route: { name: 'shelf-list' },
      },
      {
        title: 'Phiếu đặt hàng',
        // icon: 'ArchiveIcon',
        route: { name: 'purchasing-orders-list' },
      },
      {
        title: 'Phiếu nhập',
        // icon: 'ArchiveIcon',
        route: { name: 'inventory-receives-list' },
      },
      {
        title: 'Thông tin kho',
        route: { name: 'warehousev2-list' },
      },
      // {
      //   title: 'Phiếu kiểm kho',
      //   route: { name: 'stock-takes-list' },
      // },
      {
        title: 'Trả hàng',
        // icon: 'ApertureIcon',
        route: { name: 'purchase-returns-list' },
      },
    ],
  },
  {
    header: 'Shop Management',
    icon: 'MonitorIcon',
  },
  {
    title: 'Slider',
    // icon: 'ColumnsIcon',
    route: { name: 'slider-list' },
    // children: [
    //   {
    //     title: 'Slider',
    //     route: { name: 'slider-list' },
    //   },
    //   {
    //     title: 'Add new slider',
    //     route: { name: 'slider-add' },
    //   },
    // ],
  },
  {
    title: 'Collection',
    // icon: 'ColumnsIcon',
    route: { name: 'album-list' },
  },
  // {
  //   title: 'Gallery',
  //   children: [
  //     {
  //       title: 'Gallery Categories',
  //       route: { name: 'gallery-category-list' },
  //     },
  //     {
  //       title: 'Gallery',
  //       route: { name: 'gallery-list' },
  //     },
  //   ],
  // },
  {
    title: 'Blog',
    // icon: 'ListIcon',
    children: [
      {
        title: 'Blog Categories',
        route: { name: 'blog-category-list' },
      },
      // {
      //   title: 'Add new category',
      //   route: { name: 'blog-category-add' },
      // },
      {
        title: 'Blog',
        route: { name: 'blog-list' },
      },
      // {
      //   title: 'Add new blog',
      //   route: { name: 'blog-add' },
      // },
    ],
  },
  {
    title: 'Recruitment',
    // icon: 'AnchorIcon',
    children: [
      {
        title: 'Recruitment Categories',
        route: { name: 'recruitment-category-list' },
      },
      // {
      //   title: 'Add new category',
      //   route: { name: 'recruitment-category-add' },
      // },
      {
        title: 'Recruitment',
        route: { name: 'recruitment-list' },
      },
      // {
      //   title: 'Add new recruitment',
      //   route: { name: 'recruitment-add' },
      // },
    ],
  },
  {
    title: 'Service',
    // icon: 'GiftIcon',
    route: { name: 'service-list' },
    // children: [
    //   {
    //     title: 'Service',
    //     route: { name: 'service-list' },
    //   },
    //   {
    //     title: 'Add new service',
    //     route: { name: 'service-add' },
    //   },
    // ],
  },
  {
    title: 'Customer',
    // icon: 'UserIcon',
    children: [
      {
        title: 'Customer',
        route: { name: 'customer-list' },
      },
      // {
      //   title: 'Add new customer',
      //   route: { name: 'customer-add' },
      // },
      // {
      //   title: 'Comment fake',
      //   route: { name: 'customer-fake-list' },
      // },
      // {
      //   title: 'Comment',
      //   route: { name: 'comment-list' },
      // },
    ],
  },
  // {
  //   title: 'Contact',
  //   route: { name: 'contact-list' },
  // },
  {
    title: 'FAQ',
    // icon: 'HelpCircleIcon',
    // route: { name: 'faq-list' },
    children: [
      {
        title: 'FAQ',
        route: { name: 'faq-list' },
      },
      {
        title: 'FAQ Category',
        route: { name: 'faq-category-list' },
      },
    ],
  },
  {
    title: 'Policy',
    // icon: 'HelpCircleIcon',
    route: { name: 'policy-list' },
  },
  {
    title: 'About Page',
    children: [
      // {
      //   title: 'Story',
      //   route: { name: 'about-story-list' },
      // },
      {
        title: 'Rank',
        route: { name: 'rank-list' },
      },
      {
        title: 'Rank info',
        route: { name: 'rank-info-list' },
      },
      {
        title: 'Join iFashion',
        route: { name: 'join-ifashion-list' },
      },
      {
        title: 'Founder',
        route: { name: 'founder-list' },
      },
      {
        title: 'Reward quest',
        route: { name: 'reward-quest-list' },
      },
      {
        title: 'Social media',
        route: { name: 'social-media-list' },
      },
    ],
  },
  // {
  //   title: 'Order Shopee',
  //   icon: 'ArchiveIcon',
  //   route: { name: 'order-shopee-list' },
  // },
  {
    header: 'Other sales channels',
    icon: 'SidebarIcon',
  },
  // {
  //   title: 'Dashboard channels',
  //   route: { name: 'dashboard-channels' },
  // },
  // {
  //   title: 'Sales channels',
  //   icon: 'RefreshCwIcon',
  //   route: { name: 'sales-channel' },
  // },
  {
    title: 'Shopee channel',
    // icon: 'RefreshCwIcon',
    route: { name: 'sales-channel-shopee' },
  },
  {
    title: 'Lazada channel',
    // icon: 'RefreshCwIcon',
    route: { name: 'sales-channel-lazada' },
  },
  {
    title: 'Tiktok channel',
    // icon: 'RefreshCwIcon',
    route: { name: 'sales-channel-tiktok' },
  },
  // {
  //   title: 'Sales channels',
  //   // icon: 'PlusSquareIcon',
  //   route: { name: 'channel' },
  // },
  // {
  //   header: 'Collaborators',
  //   icon: 'DollarSignIcon',
  // },
  // {
  //   title: 'Collaborators List',
  //   route: { name: 'collaborators-list' },
  // },
  // {
  //   title: 'Collaborators Paying',
  //   route: { name: 'collaborators-paying' },
  // },
  // {
  //   title: 'Levels',
  //   route: { name: 'levels-list' },
  // },
  // {
  //   title: 'Commission money',
  //   route: { name: 'commissionmoney-list' },
  // },
  {
    title: 'Discount settings',
    route: { name: 'discount-list' },
    // children: [
    //   {
    //     title: 'Create Discount',
    //     route: { name: 'discount-add' },
    //   },
    //   {
    //     title: 'Discount List',
    //     route: { name: 'discount-list' },
    //   },
    // ],
  },
  {
    header: 'Settings',
    icon: 'ToolIcon',
  },
  // {
  //   title: 'User',
  //   // icon: 'ApertureIcon',
  //   route: { name: 'user-list' },
  // },
  // {
  //   title: 'Departments',
  //   // icon: 'ApertureIcon',
  //   route: { name: 'departments-list' },
  // },
  {
    title: 'Domain',
    // icon: 'HelpCircleIcon',
    route: { name: 'domain-list' },
    // children: [
    //   {
    //     title: 'Domain',
    //     route: { name: 'domain-list' },
    //   },
    //   {
    //     title: 'Add new domain',
    //     route: { name: 'domain-add' },
    //   },
    // ],
  },
  {
    title: 'Setting',
    // icon: 'SettingsIcon',
    children: [
      // {
      //   title: 'About Page',
      //   route: { name: 'about-page' },
      // },
      {
        title: 'Language',
        route: { name: 'language' },
      },
      {
        title: 'Currency',
        route: { name: 'currency' },
      },
      {
        title: 'Payment Account',
        route: { name: 'payment-account' },
      },
      {
        title: 'Config',
        route: { name: 'config' },
      },
      {
        title: 'Facebook',
        route: { name: 'setting-fb' },
      },
    ],
  },
]
