<template>
  <div
    class="main-menu menu-fixed menu-accordion menu-shadow dark-layout"
    :class="[
      {
        expanded:
          !isVerticalMenuCollapsed ||
          (isVerticalMenuCollapsed && isMouseHovered),
      },
      skin === 'semi-dark' ? 'menu-dark' : 'menu-light',
    ]"
    @mouseenter="updateMouseHovered(true)"
    @mouseleave="updateMouseHovered(false)"
  >
    <!-- main menu header-->
    <div class="navbar-header expanded">
      <slot
        name="header"
        :toggleVerticalMenuActive="toggleVerticalMenuActive"
        :toggleCollapsed="toggleCollapsed"
        :collapseTogglerIcon="collapseTogglerIcon"
      >
        <a
          class="navbar-brand"
          :href="`https://beta-editor.busodevelopers.com/auth/login?domain=${siteData.domain_name}&admin_token=${userData.access_token}`"
          target="_blank"
        >
          <div class="brand-logo w-100">
            <b-img
              :src="config.logo || appLogoImage"
              alt="logo"
              class="w-100"
            />
          </div>
        </a>

        <!-- <ul class="nav navbar-nav flex-row"> -->
        <!-- Logo & Text -->
        <!-- <li class="nav-item mr-auto w-100">
            <b-link class="navbar-brand" to="/">
              <div class="brand-logo w-100">
                <b-img :src="appLogoImage" alt="logo" class="w-100" />
              </div>
              <h2 class="brand-text">
                {{ appName }}
              </h2>
            </b-link>
          </li> -->

        <!-- Toggler Button -->
        <!-- <li class="nav-item nav-toggle">
            <b-link class="nav-link modern-nav-toggle">
              <feather-icon
                icon="XIcon"
                size="20"
                class="d-block d-xl-none"
                @click="toggleVerticalMenuActive"
              />
              <feather-icon
                :icon="collapseTogglerIconFeather"
                size="20"
                class="d-none d-xl-block collapse-toggle-icon"
                @click="toggleCollapsed"
              />
            </b-link>
          </li> -->
        <!-- </ul> -->
      </slot>
    </div>
    <!-- / main menu header-->

    <!-- Shadow -->
    <div
      :class="{ 'd-block': shallShadowBottom }"
      class="shadow-bottom"
    />

    <!-- main menu content-->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="main-menu-content scroll-area"
      tagname="ul"
      @ps-scroll-y="
        evt => {
          shallShadowBottom = evt.srcElement.scrollTop > 0
        }
      "
    >
      <vertical-nav-menu-items
        :items="navMenuItems"
        class="navigation navigation-main"
      />
    </vue-perfect-scrollbar>
    <!-- /main menu content-->
  </div>
</template>

<script>
/* eslint-disable no-confusing-arrow */
/* eslint-disable no-confusing-arrow */
/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
import Request from '@/plugins/service/index'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { BImg } from 'bootstrap-vue'
import { provide, computed, ref } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import { $themeConfig } from '@themeConfig'
import navMenuItems from '@/navigation/vertical'
import VerticalNavMenuItems from './components/vertical-nav-menu-items/VerticalNavMenuItems.vue'
// import VerticalNavMenuItems from './components/vertical-nav-menu-items/VerticalNavMenuItemsV2.vue'
import useVerticalNavMenu from './useVerticalNavMenu'

export default {
  components: {
    VuePerfectScrollbar,
    VerticalNavMenuItems,
    BImg,
  },
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true,
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const {
      isMouseHovered,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      updateMouseHovered,
    } = useVerticalNavMenu(props)

    const { skin } = useAppConfig()

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false)

    provide('isMouseHovered', isMouseHovered)

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }
    const collapseTogglerIconFeather = computed(() =>
      collapseTogglerIcon.value === 'unpinned' ? 'CircleIcon' : 'DiscIcon',
    )

    // App Name
    const { appName, appLogoImage } = $themeConfig.app

    return {
      navMenuItems,
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,

      // Shadow Bottom
      shallShadowBottom,

      // Skin
      skin,

      // App Name
      appName,
      appLogoImage,
    }
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('token')),
      siteData: JSON.parse(localStorage.getItem('siteID')),
      config: {},
    }
  },
  computed: {
    temp() {
      return process.env.VUE_APP_FRONTEND_TEMP
    },
  },
  created() {
    this.loadConfig()
  },
  methods: {
    async loadConfig() {
      const res = await Request.get(
        this.$http,
        `${process.env.VUE_APP_API_URL}/setting/buso_client_page_config?site_id=${
          JSON.parse(localStorage.getItem('siteID')).id
        }`,
      )
      if (res.status === 200) {
        if (res.data.status) {
          this.isExistSetting = true
          const config = JSON.parse(res.data.data.value)
          config.map(val => {
            const temp = {}
            temp[val.key] = val.value
            this.config = { ...this.config, ...temp }
            return true
          })
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/core/menu/menu-types/vertical-menu.scss';
</style>

<style scoped>
.main-menu .navbar-header .navbar-brand .brand-logo img {
  max-width: 150px;
  margin: 0 auto;
  display: block;
}
.main-menu .navbar-header .navbar-brand {
  margin-top: 0;
  margin-right: 0;
}
.dark-layout.main-menu {
  background-color: #283046;
}
.dark-layout.main-menu .shadow-bottom {
  background: linear-gradient(180deg, #283046 44%, rgba(40, 48, 70, 0.51) 73%, rgba(40, 48, 70, 0));
}
</style>
