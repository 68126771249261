<template>
  <b-nav-item-dropdown
    v-if="locales && locales.length > 0"
    id="dropdown-grouped"
    variant="link"
    class="dropdown-language"
    right
  >
    <template #button-content>
      <b-img
        :src="currentLocale.img ? currentLocale.img : ''"
        height="14px"
        width="22px"
        :alt="currentLocale.locale"
      />
      <span class="ml-50 text-body">{{ currentLocale.name }}</span>
    </template>
    <b-dropdown-item
      v-for="localeObj in locales"
      :key="localeObj.locale"
      @click="$i18n.locale = localeObj.locale"
    >
      <b-img
        :src="localeObj.img"
        height="14px"
        width="22px"
        :alt="localeObj.locale"
      />
      <span class="ml-50">{{ localeObj.name }}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import { BNavItemDropdown, BDropdownItem, BImg } from 'bootstrap-vue'
// import language from '@/libs/lang/lang'
import general from '@/mixins/index'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BImg,
  },
  mixins: [general],
  data() {
    return {
      locales: [],
    }
  },
  // ! Need to move this computed property to comp function once we get to Vue 3
  computed: {
    currentLocale() {
      return this.locales.find(l => l.locale === this.$i18n.locale)
    },
  },
  // setup() {
  //   const locales = language.language
  //   return {
  //     locales,
  //   }
  // },
  async mounted() {
    this.locales = await this.loadLanguage()
  },
}
</script>
