export default [
  {
    header: 'Affiliate',
  },
  {
    title: 'General',
    route: { name: 'affiliate-general' },
  },
  // {
  //   title: 'Commission Setting',
  //   route: { name: 'affiliate-general' },
  //   children: [
  //     {
  //       title: 'Commission Categories',
  //       route: { name: 'affiliate-commission-category' },
  //     },
  //     {
  //       title: 'Commission Products',
  //       route: { name: 'affiliate-commission-product' },
  //     },
  //     {
  //       title: 'Referrals',
  //       route: { name: 'affiliate-referrals-add' },
  //     },
  //     {
  //       title: 'Payment',
  //       route: { name: 'affiliate-payment-list' },
  //     },
  //   ],
  // },
  // {
  //   title: 'Thành viên',
  //   route: { name: 'affiliate-member-list' },
  // },
  // {
  //   title: 'Rank segment',
  //   route: { name: 'affiliate-rank-segment' },
  // },
]
